import { createSlice } from "@reduxjs/toolkit";

export const OrdersSlice = createSlice({
  name: "orders",
  initialState: {
    orders: null,
    filter: "All",
    updateErrors: false,
    deleteErrors: null,
    OneOrder: "",
    BTC: "pending",
    USDT: "pending",
  },
  reducers: {
    GetAllOrders: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.orders = action.payload;
    },
    GetOneOrder: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.OneOrder = action.payload;
    },
    updateBTC: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.BTC = action.payload;
    },
    updateUSDT: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.USDT = action.payload;
    },
    updateError: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      console.log(action, "action payload update error");
      state.updateErrors = action.payload;
    },
    deleteError: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      console.log(action, "action payload delete error");

      state.deleteErrors = action.payload;
    },
    setfilterRedux: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.filter = action.payload;
    },
    errorAtGetAllOrders: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  GetAllOrders,
  errorAtGetAllOrders,
  GetOneOrder,
  setfilterRedux,
  deleteError,
  updateError,
  updateUSDT,
  updateBTC,
} = OrdersSlice.actions;

export default OrdersSlice.reducer;
