import React from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function App({ text, success }) {
  return (
    <div>
      <ToastContainer autoClose={1000}
 />
    </div>
  );
}
