import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import './style.css'

// Telegram bot React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Telegram bot React example components
import Button from "@mui/material/Button";
import { useGetOrdersApi } from "apis/Orders";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Orders/DataTable";
import * as React from "react";

// Data
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useCreateUserApi } from "apis/Users";
import authorsTableDataBTC from "layouts/Orders/data/authorsTableDataBTC";
import projectsTableData from "layouts/Orders/data/projectsTableData";
import { useDispatch, useSelector } from "react-redux";
import { setfilterRedux } from "../../store/OrdersStore/Slice";
function Tables() {
  const [open, setOpen] = React.useState(false);
  const [whichbtnClickfilter, setwhichbtnClickfilter] = React.useState("All");

  const [email, setemail] = React.useState("");
  const [user_name, setuser_name] = React.useState("");
  const [chat_id, setchat_id] = React.useState("");
  const [contact, setcontact] = React.useState("");
  const [bank, setbank] = React.useState("");
  const [bank_no, setbank_no] = React.useState("");
  const [wallet, setwallet] = React.useState("");
  const [wallet_add, setwallet_add] = React.useState("");
  const [kyc_status, setkyc_status] = React.useState("");
  const [active, setactive] = React.useState(false);
  const { mutate: CreateUserApi } = useCreateUserApi();
  const [refresh, setrefresh] = useState(false);
  const { orders, filter } = useSelector((state) => state.Orders);

  const [search, setSearch] = useState("");


  const { data: GetOrdersApi } = useGetOrdersApi(refresh);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let { columns, rows: rowscus } = authorsTableDataBTC();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const [rows, setfilterRows] = useState([]);
  const [whichbtnClick, setwhichbtnClick] = useState("sell");
  const dispatch = useDispatch();
  const dispatchFilter = (arg) => {
    dispatch(setfilterRedux(arg));
  };
  useEffect(() => {
    const data = GetOrdersApi;
  }, []);
  const submitUser = () => {
    CreateUserApi({
      email,
      user_name,

      kyc_status,
      bank_no,
      bank,
      wallet_add,
      wallet,
      contact,
      chat_id,
    });
  };
  useEffect(() => {
    let filted = rowscus.filter((item) => item.is_buy != "buy");
    setfilterRows(filted);
  }, []);
  const filtersellOrBuy = (arg, sec) => {
    // const {rows}=authorsTableData(arg)
    if (arg == "sell") {
      let filted = rowscus.filter((item) => item.is_buy != "buy");
      setfilterRows(filted);

      setwhichbtnClick("sell");
      setwhichbtnClickfilter("All");
    } else if ("buy") {
      let filted = rowscus.filter((item) => item.is_buy == "buy");
      setfilterRows(filted);
      setwhichbtnClick("buy");
      setwhichbtnClickfilter("All");
    }
  };
  const secfilter = () => {
    let sec = rowscus.filter(
      (item) => item.status == whichbtnClickfilter && item.is_buy == whichbtnClick
    );
    setfilterRows(sec);
    //  console.log(sec, rows, "seccc");
  };
  useEffect(() => {
    setwhichbtnClickfilter(filter);
    secfilter();
  }, []);
  useEffect(() => {
    secfilter();
    dispatchFilter(whichbtnClickfilter);
  }, [whichbtnClickfilter]);

  const handleinputchange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography variant="h6" color="white">
                  Orders
                </MDTypography>
                <Button
                  style={{ display: "none" }}
                  onClick={handleClickOpen}
                  variant="contained"
                  color="error"
                >
                  Create
                </Button>
              </MDBox>

              <MDBox
                className="overflow_mobile sell-buy-search-sec"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDBox
                  mx={2}
                  mt={1}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="transparent"
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className={whichbtnClick != "sell" && "inactive-status-btn"}
                    style={{
                      display: "block",
                      marginInline: "10px",
                      backgroundColor: whichbtnClick == "sell" ? "green" : "white",
                      color: whichbtnClick == "sell" ? "white" : "black",
                      minWidth: "auto",
                    }}
                    onClick={() => filtersellOrBuy("sell")}
                    variant="contained"
                    color="error"
                  >
                    Sell BTC
                  </Button>
                  <Button
                    className={whichbtnClick != "buy" && "inactive-status-btn"}
                    style={{
                      display: "block",
                      backgroundColor: whichbtnClick == "buy" ? "green" : "white",
                      color: whichbtnClick == "buy" ? "white" : "black",
                      minWidth: "auto",
                    }}
                    onClick={() => filtersellOrBuy("buy")}
                    variant="contained"
                    color="error"
                  >
                    Buy BTC
                  </Button>
                </MDBox>

                <MDBox className="orders-search-csv">
                  <TextField
                    onChange={(e) => handleinputchange(e)}
                    label="Search"
                    color="primary"
                    placeholder="Search by User ID, Chat ID, Fiat or Crypto amount or Order Status"
                    className="search-input"
                  />
                  <a
                    href="https://botmin.sinchron.io/api/v1/get_order_csv_report"
                    target="_blank"
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "green",
                      marginInline: "10px",
                      color: "white",
                      borderRadius: "8px",
                      fontSize: "14px",
                      boxShadow:
                        "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                      // backgroundColor: whichbtnClick == "sell" ? "green" : "white",
                      /// color: whichbtnClick == "sell" ? "white" : "black",
                    }}
                    variant="contained"
                    color="error"
                  >
                    Export CSV
                  </a>
                </MDBox>
              </MDBox>

              <MDBox
                mx={2}
                mt={0}
                py={1}
                px={2}
                variant="gradient"
                bgColor="transparent"
                className="overflow_mobile status-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  className={
                    whichbtnClickfilter == "All"
                      ? "status-btn active-status-btn"
                      : "status-btn inactive-status-btn"
                  }
                  onClick={() => setwhichbtnClickfilter("All")}
                  variant="contained"
                  color="error"
                >
                  All
                </Button>

                <Button
                  className={
                    whichbtnClickfilter == "pending"
                      ? "status-btn active-status-btn"
                      : "status-btn inactive-status-btn"
                  }
                  onClick={() => setwhichbtnClickfilter("pending")}
                  variant="contained"
                  color="error"
                >
                  pending
                </Button>
                <Button
                  className={
                    whichbtnClickfilter == "approved"
                      ? "status-btn active-status-btn"
                      : "status-btn inactive-status-btn"
                  }
                  onClick={() => setwhichbtnClickfilter("approved")}
                  variant="contained"
                  color="error"
                >
                  approved
                </Button>
                <Button
                  className={
                    whichbtnClickfilter == "paid"
                      ? "status-btn active-status-btn"
                      : "status-btn inactive-status-btn"
                  }
                  onClick={() => setwhichbtnClickfilter("paid")}
                  variant="contained"
                  color="error"
                >
                  paid
                </Button>
                <Button
                  className={
                    whichbtnClickfilter == "completed"
                      ? "status-btn active-status-btn"
                      : "status-btn inactive-status-btn"
                  }
                  onClick={() => setwhichbtnClickfilter("completed")}
                  variant="contained"
                  color="error"
                >
                  completed
                </Button>

                <Button
                  className={
                    whichbtnClickfilter == "canceled"
                      ? "status-btn active-status-btn"
                      : "status-btn inactive-status-btn"
                  }
                  onClick={() => setwhichbtnClickfilter("canceled")}
                  variant="contained"
                  color="error"
                >
                  canceled
                </Button>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{
                    columns,
                    rows:
                      whichbtnClickfilter == "All"
                        ? rowscus
                            .filter((item) => item.is_buy == whichbtnClick)
                            .filter((o) => {
                              return (
                                o.fiat_value.includes(search) ||
                                o.crypto_value.includes(search) ||
                                o.chat_id == search ||
                                o.id == search ||
                                o.status.toLowerCase().includes(search.toLowerCase())
                              );
                            })
                        : rowscus
                            .filter((item) => item.is_buy == whichbtnClick)
                            .filter((item) => item.status == whichbtnClickfilter)
                            .filter((o) => {
                              return (
                                o.fiat_value.includes(search) ||
                                o.crypto_value.includes(search) ||
                                o.chat_id == search ||
                                o.id == search ||
                                o.status.toLowerCase().includes(search.toLowerCase())
                              );
                            }),
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle> Create Orders</DialogTitle>
          <DialogContent>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="user_name"
                  label="user name "
                  fullWidth
                  variant="outlined"
                  value={user_name}
                  onChange={(e) => setuser_name(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="order_date"
                  label="order_date "
                  type="date"
                  fullWidth
                  variant="outlined"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="chat_id"
                  label="chat id "
                  fullWidth
                  variant="outlined"
                  value={chat_id}
                  onChange={(e) => setchat_id(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="contact"
                  label=" contact "
                  fullWidth
                  variant="outlined"
                  value={contact}
                  onChange={(e) => setcontact(e.target.value)}
                />
              </Grid>{" "}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="bank"
                  label=" bank "
                  fullWidth
                  variant="outlined"
                  value={bank}
                  onChange={(e) => setbank(e.target.value)}
                />
              </Grid>{" "}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="bank_no"
                  label="bank no  "
                  fullWidth
                  variant="outlined"
                  value={bank_no}
                  onChange={(e) => setbank_no(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="wallet"
                  label=" wallet "
                  fullWidth
                  variant="outlined"
                  value={wallet}
                  onChange={(e) => setwallet(e.target.value)}
                />
              </Grid>{" "}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="wallet_add"
                  label=" wallet address "
                  fullWidth
                  variant="outlined"
                  value={wallet_add}
                  onChange={(e) => setwallet_add(e.target.value)}
                />
              </Grid>{" "}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="kyc_status"
                  label=" kyc_status "
                  fullWidth
                  variant="outlined"
                  value={kyc_status}
                  onChange={(e) => setkyc_status(e.target.value)}
                />
              </Grid>{" "}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="active"
                  label=" active "
                  fullWidth
                  variant="outlined"
                />
              </Grid>{" "}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={submitUser}>Create</Button>
          </DialogActions>
        </Dialog>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
