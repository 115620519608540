import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Telegram bot React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Telegram bot React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/AdminUsers/DataTable";
import { useGetGroupsApi } from "apis/Groups";
import Button from "@mui/material/Button";
import * as React from "react";
import Box from "@mui/material/Box";

// Data
import authorsTableData from "layouts/AdminUsers/data/authorsTableData";
import projectsTableData from "layouts/AdminUsers/data/projectsTableData";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useCreateAdminUserApi } from "apis/Admin_users";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useSelector, useDispatch } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  { id: 1, name: "Oliver Hansen" },
  { id: 2, name: "Oliver Hansen" },
];
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function Tables() {
  const theme = useTheme();
  const { Groups, error } = useSelector((state) => state.Groups);

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const [referesh, setreferesh] = React.useState(false);

  const [email, setemail] = React.useState("");
  const [user_name, setuser_name] = React.useState("");
  const [password, setpassword] = React.useState("");
  const [first_name, setfirst_name] = React.useState("");
  const [last_name, setlast_name] = React.useState("");
  const [is_active, setis_active] = React.useState("");
  const [is_staff, setis_staff] = React.useState("");
  const [is_verified, setis_verified] = React.useState("");
  const [Permissions, setPermissions] = React.useState([]);

  const { mutate: CreateAdminUserApi } = useCreateAdminUserApi();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPermissions(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const { data: GetGroupsApi, refetch } = useGetGroupsApi();

  const { columns, rows } = authorsTableData();

  useEffect(() => {}, []);
  const submitUser = () => {
    var permissions = [];
    for (var i = 0; i < Permissions.length; i++) {
      permissions.push(Permissions[i].id);
    }

    CreateAdminUserApi({
      email,
      password,
      first_name,
      last_name,
      is_active,
      is_superuser: is_staff ? true : false,
      is_staff,
      is_verified,
      groups: permissions,
    });
    refetch();
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography variant="h6" color="white">
                  users
                </MDTypography>
                <Button onClick={handleClickOpen} variant="contained" color="error">
                  Create
                </Button>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                  refersh={referesh}
                  setrefresh={setreferesh}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle> Create users</DialogTitle>
          <DialogContent>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id=" first name"
                  label="first name"
                  fullWidth
                  variant="outlined"
                  value={first_name}
                  onChange={(e) => setfirst_name(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="last name"
                  label="last name"
                  fullWidth
                  variant="outlined"
                  value={last_name}
                  onChange={(e) => setlast_name(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="email"
                  label=" email"
                  type="email"
                  fullWidth
                  variant="outlined"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="email"
                  label=" password"
                  type="password"
                  fullWidth
                  variant="outlined"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingTop: " 57px" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">is active</InputLabel>
                  <Select
                    style={{ height: " 41px" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={is_active}
                    label="is active"
                    onChange={(e) => setis_active(e.target.value)}
                  >
                    <MenuItem value={true}>yes</MenuItem>
                    <MenuItem value={false}>no</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} style={{ paddingTop: " 57px" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">is staff</InputLabel>
                  <Select
                    style={{ height: " 41px" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={is_staff}
                    label="is staff"
                    onChange={(e) => setis_staff(e.target.value)}
                  >
                    <MenuItem value={true}>yes</MenuItem>
                    <MenuItem value={false}>no</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} style={{ paddingTop: " 57px" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label"> is_verified</InputLabel>
                  <Select
                    style={{ height: " 41px" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={is_verified}
                    label="is verified"
                    onChange={(e) => setis_verified(e.target.value)}
                  >
                    <MenuItem value={true}>yes</MenuItem>
                    <MenuItem value={false}>no</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} style={{ paddingTop: " 57px" }}>
                <FormControl sx={{ m: 1, width: "100%" }}>
                  <InputLabel id="demo-multiple-chip-label">groups</InputLabel>
                  <Select
                    style={{ height: " 41px" }}
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={Permissions}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value.id} label={value.name} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {Groups?.map((name) => (
                      <MenuItem
                        key={name.id}
                        value={name}
                        style={getStyles(name, Permissions, theme)}
                      >
                        {name.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>{" "}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={(e) => submitUser()}>Create</Button>
          </DialogActions>
        </Dialog>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
