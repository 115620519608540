import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { useSelector, useDispatch } from "react-redux";
import { useDeleteMarginApi, useGetMarginsApi } from "apis/margins";
import { useEffect, useState } from "react";
import Modal from "../modal";
import Toaster from "components/Toaster/index";
import { Link } from "react-router-dom";
export default function data(SGDClicked2) {
  const [open2, setOpen2] = useState([]);
  const [refresh, setrefresh] = useState(false);

  const [reresh, setreresh] = useState(false);
  const { margins } = useSelector((state) => state.Margins);
  const { live, SGDClicked, liveSGD } = useSelector((state) => state.Margins);
  const [filterDate, setFilterDate] = useState([]);

  const { data: GetMarginsApi } = useGetMarginsApi(refresh);

  const { isLoading, error, mutate: DeleteMarginApi } = useDeleteMarginApi();

  useEffect(() => {
    var filter = margins?.filter((item) => item.usdt == true);
    setFilterDate(filter);
  }, [margins, refresh]);
  const HandleDelete = (id) => {
    DeleteMarginApi(id);
    // refetch();
    setTimeout(() => {
      setrefresh(!refresh);
    }, 1000);
  };
  useEffect(() => {
    var numofmodals = [];
    for (var i = 0; i < filterDate?.length; i++) {
      numofmodals.push(false);
    }
    setOpen2([...numofmodals]);
  }, [filterDate]);
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Data = (SGDClicked3) => {
    var rate = SGDClicked3 == false ? liveSGD?.bitcoin?.sgd : live?.bitcoin?.gbp;
    let arrays = [];
    if (filterDate) {
      for (var i = 0; i < filterDate?.length; i++) {
        arrays.push({
          buy: filterDate[i].buy,
          sell: filterDate[i].sell,
          currency: filterDate[i].currency,
          function: (
            <Link to={`/margin/edit?id=${filterDate[i].id}`}>
              <Job title={`${filterDate[i].margin * 100} %`} description="" />{" "}
            </Link>
          ),
          Date: (
            <Link to={`/margin/edit?id=${filterDate[i].id}`}>
              <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                {`${
                  Math.floor(
                    (filterDate[i].margin + 1) *
                      (filterDate[i].currency == "SGD" ? liveSGD?.tether?.sgd : live?.tether?.gbp) *
                      10000
                  ) / 10000
                }` +
                  " " +
                  filterDate[i].currency}
              </MDTypography>
            </Link>
          ),
          employed: (
            <Link to={`/margin/edit?id=${filterDate[i].id}`}>
              <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                {filterDate[i].inf_limit_range + "-" + filterDate[i].sup_limit_range}
              </MDTypography>
            </Link>
          ),
          action: (
            <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
              <Button
                onClick={(e) => {
                  let id = filterDate[i]?.id;
                  HandleDelete(e.target.id);
                }}
                style={{ backgroundColor: "#f44336", color: "white" }}
                variant="contained"
                id={filterDate[i]?.id}
              >
                delete
              </Button>
            </MDTypography>
          ),
        });
      }
    }

    return arrays;
  };
  return {
    columns: [
      { Header: "Margins", accessor: "function", align: "left" },
      { Header: "Rates", accessor: "Date", align: "center" },
      { Header: "Min-Max", accessor: "employed", align: "center" },
      { Header: "actions", accessor: "action", align: "center" },
    ],

    rows: margins ? Data(SGDClicked2).reverse() : [],
  };
}
