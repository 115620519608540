import { createSlice } from "@reduxjs/toolkit";

export const MarginsSlice = createSlice({
  name: "margins",
  initialState: {
    margins: null,
    live:null,
    liveSGD:null,
    SGDClicked:false,
    error: null,
    OneMargin:null
  },
  reducers: {
    GetAllMargins: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.margins = action.payload;
    },
    GetOneMargin: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.OneMargin = action.payload;
    },
    GetLiveMarginsState:(state, action)=>{
      state.live = action.payload;
    },
    GetLiveSGDMarginsState:(state, action)=>{
      state.liveSGD = action.payload;
    },
    DoesSGDClicked:(state, action)=>{
      state.SGDClicked = action.payload;
    },
    errorAtGetAllMargins: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { GetAllMargins, errorAtGetAllMargins,GetLiveMarginsState,GetLiveSGDMarginsState,DoesSGDClicked,GetOneMargin } = MarginsSlice.actions;

export default MarginsSlice.reducer;
