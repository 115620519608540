import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { login, errorAtLogin } from "../layouts/authentication/AuthStore/Slice";
import {
  errorAtGetAllMargins,
  GetAllMargins,
  GetLiveMarginsState,
  GetLiveSGDMarginsState,
  GetOneMargin,
} from "store/MarginsStore/Slice";
import { BASE_URL } from "./BaseURL";



const getMarginRequest = async () => {
  const items = JSON.parse(localStorage.getItem("user"));

  return await axios.get(`${BASE_URL}/api/v1/margins`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const getOneMarginRequest = async (data) => {
  const items = JSON.parse(localStorage.getItem("user"));

  return await axios.get(`${BASE_URL}/api/v1/margins?id=${data.id}`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const getliveMargin = async () => {
  const items = JSON.parse(localStorage.getItem("user"));

  const result = await axios.get(`${BASE_URL}/api/v1/rates?currency=gbp`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
  return result;
};
const getliveSGDMargin = async () => {
  const items = JSON.parse(localStorage.getItem("user"));

  return await axios.get(`${BASE_URL}/api/v1/rates?currency=sgd`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const PostEditMargin = async (data) => {
  const items = JSON.parse(localStorage.getItem("user"));
  return await axios.put(`${BASE_URL}/api/v1/margins/${data.id}`, data, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};

const PostMargins = async (data) => {
  const items = JSON.parse(localStorage.getItem("user"));

  return await axios.post(`${BASE_URL}/api/v1/margins`, data, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const PostDeleteMarginrequest = async (id) => {
  const items = JSON.parse(localStorage.getItem("user"));
  return await axios.delete(`${BASE_URL}/api/v1/margins/${id}`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const usePostMarginsApi = () => {
  const dispatch = useDispatch();

  return useMutation(PostMargins, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      return result;
    },
    onError: (err) => {
      dispatch(errorAtGetAllMargins(err.message));

      return err;
    },
  });
};
const useGetMarginsApi = (refresh) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useQuery(["margins", refresh], getMarginRequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      dispatch(GetAllMargins(result.data));

      return result;
    },
    onError: (err) => {
      //  return err;
    },
  });
};

const useGetOneMarginApi = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useMutation(getOneMarginRequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      dispatch(GetOneMargin(result.data[0]));
      // return result;
    },
    onError: (err) => {
      // dispatch(errorAtGetAllUsers(err.response));
      //  return err;
    },
  });
};

const useGetLiveMarginsApi = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useQuery("live", getliveMargin, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      dispatch(GetLiveMarginsState(result.data));

      return result;
    },
    onError: (err) => {
      //  return err;
    },
  });
};
const useGetLiveSGDMarginsApi = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useQuery("livesgd", getliveSGDMargin, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      dispatch(GetLiveSGDMarginsState(result.data));

      return result;
    },
    onError: (err) => {
      //  return err;
    },
  });
};
const useDeleteMarginApi = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useMutation(PostDeleteMarginrequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      //  dispatch(login(result));
      // return result;
    },
    onError: (err) => {
      // dispatch(errorAtGetAllUsers(err.response));
      //  return err;
    },
  });
};

const useupdatemarginApi = () => {
  return useMutation(PostEditMargin, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      return result;
    },
    onError: (err) => {
      return err;
    },
  });
};
export {
  useGetMarginsApi,
  useGetLiveMarginsApi,
  useGetLiveSGDMarginsApi,
  usePostMarginsApi,
  useGetOneMarginApi,
  useupdatemarginApi,
  useDeleteMarginApi,
};
