import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
// Telegram bot React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Typography from "@mui/material/Typography";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../components/Toaster/index";

// Telegram bot React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Margins/DataTable";
import { useGetMarginsApi, useGetLiveMarginsApi } from "apis/margins";
import Button from "@mui/material/Button";
import * as React from "react";
import { DoesSGDClicked } from "../../store/MarginsStore/Slice";
import { useSelector, useDispatch } from "react-redux";

// Data
import authorsTableDataBTC from "layouts/Margins/data/authorsTableDataBTC";
import projectsTableData from "layouts/Margins/data/projectsTableData";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { usePostMarginsApi, useGetLiveSGDMarginsApi } from "apis/margins";
import { Divider } from "@mui/material";

function Tables() {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const [email, setemail] = React.useState("");
  const [user_name, setuser_name] = React.useState("");
  const [chat_id, setchat_id] = React.useState("");
  const [contact, setcontact] = React.useState("");
  const [bank, setbank] = React.useState("");
  const [bank_no, setbank_no] = React.useState("");
  const [wallet, setwallet] = React.useState("");
  const [wallet_add, setwallet_add] = React.useState("");
  const [kyc_status, setkyc_status] = React.useState("");
  const [active, setactive] = React.useState(false);
  const [refresh, setrefresh] = React.useState(false);

  const [max, setMax] = React.useState(null);
  const [min, setMin] = React.useState(null);
  const [margin, setMargin] = React.useState(null);

  const { mutate: CreateMarginApi } = usePostMarginsApi();
  const { data: GetLiveSGDMarginsApi } = useGetLiveSGDMarginsApi();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { data: GetMarginsApi } = useGetMarginsApi(refresh);
  const { data: GetliveMarginsApi } = useGetLiveMarginsApi();
  const { live, SGDClicked, liveSGD, error } = useSelector((state) => state.Margins);

  let { columns, rows: rowscus } = authorsTableDataBTC(SGDClicked);
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const [rows, setfilterRows] = useState(null);
  const [whichbtnClick, setwhichbtnClick] = useState("sell");
  const [whichbtnCurencyClick, setwhichbtnCurencyClick] = useState("All");
  const [StaticRows, setStaticRows] = useState([]);
  const notify = (success) => {
    if (success) {
      toast.success("margin created Successfully !");
    } else {
      toast.error("error!");
    }
  };

  useEffect(() => {
    const data = GetMarginsApi;
    const liveCurrency = GetliveMarginsApi;
  }, []);
  const handleSubmit = async () => {
    CreateMarginApi({
      sup_limit_range: max,
      inf_limit_range: min,
      margin: margin / 100,
      buy: whichbtnClick == "buy" ? true : false,
      sell: whichbtnClick == "sell" ? true : false,
      btc: true,
      usdt: false,
      currency: whichbtnCurencyClick == "SGD" ? "SGD" : "GBP",
    });
    if (!max || !min || !margin) {
      notify(false);
    } else {
      notify(true);
    }
    setTimeout(() => {}, 500);
    setwhichbtnCurencyClick("All");

    setTimeout(() => {
      setrefresh(!refresh);
    }, 1000);
  };
  const filtersellOrBuy = (arg) => {
    // const {rows}=authorsTableData(arg)
    if (arg == "sell") {
      let filted = rowscus.filter((item) => item.sell == true);
      setfilterRows(filted);
      setwhichbtnClick("sell");
      setStaticRows(filted);
    } else if ("buy") {
      let filted = rowscus.filter((item) => item.buy == true);
      setfilterRows(filted);
      setwhichbtnClick("buy");
      setStaticRows(filted);
    }
  };
  const filterSGDOrGBP = (arg) => {
    if (arg == "SGD") {
      dispatch(DoesSGDClicked(true));
      dispatch(DoesSGDClicked(true));

      let filted = rowscus.filter((item) => {
        if (whichbtnClick == "sell") {
          return item.currency == "SGD" && item.buy == false;
        } else {
          return item.currency == "SGD" && item.buy == true;
        }
      });
      setfilterRows(filted);
      setwhichbtnCurencyClick("SGD");
    } else if (arg == "GBP") {
      dispatch(DoesSGDClicked(false));

      let filted = rowscus.filter((item) => {
        if (whichbtnClick == "sell") {
          return item.currency == "GBP" && item.buy == false;
        } else {
          return item.currency == "GBP" && item.buy == true;
        }
      });
      setfilterRows(filted);
      setwhichbtnCurencyClick("GBP");
    } else {
      dispatch(DoesSGDClicked(false));

      let filted = rowscus.filter((item) => {
        if (whichbtnClick == "sell") {
          return item.buy == false;
        } else {
          return item.buy == true;
        }
      });
      setfilterRows(filted);
      setwhichbtnCurencyClick("All");
    }
  };
  useEffect(() => {
    /* setTimeout(() => {
      filtersellOrBuy("sell")

    },1000);*/
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography variant="h6" color="white">
                  Orders
                </MDTypography>
                <Button
                  style={{ display: "none" }}
                  onClick={handleClickOpen}
                  variant="contained"
                  color="error"
                >
                  Create
                </Button>
              </MDBox>
              <MDBox
                mx={2}
                mt={1}
                py={3}
                px={2}
                variant="gradient"
                bgColor="transparent"
                style={{ display: "flex", justifyContent: "start", alignItems: "center" }}
              >
                <Button
                  style={{
                    display: "block",
                    marginInline: "10px",
                    backgroundColor: whichbtnClick == "sell" ? "green" : "white",
                    color: whichbtnClick == "sell" ? "white" : "black",
                  }}
                  onClick={() => filtersellOrBuy("sell")}
                  variant="contained"
                  color="error"
                >
                  Sell Margins
                </Button>
                <Button
                  style={{
                    display: "block",
                    backgroundColor: whichbtnClick == "buy" ? "green" : "white",
                    color: whichbtnClick == "buy" ? "white" : "black",
                  }}
                  onClick={() => filtersellOrBuy("buy")}
                  variant="contained"
                  color="error"
                >
                  Buy Margins
                </Button>
              </MDBox>
              <MDBox
                mx={2}
                mt={0}
                py={1}
                px={2}
                variant="gradient"
                bgColor="transparent"
                style={{ display: "flex", justifyContent: "start", alignItems: "center" }}
              >
                <Button
                  style={{
                    display: "block",
                    marginInline: "10px",
                    backgroundColor: whichbtnCurencyClick == "All" ? "green" : "white",
                    color: whichbtnCurencyClick == "All" ? "white" : "black",
                  }}
                  onClick={() => filterSGDOrGBP("All")}
                  variant="contained"
                  color="error"
                >
                  All
                </Button>
                <Button
                  style={{
                    display: "block",
                    marginInline: "10px",
                    backgroundColor: whichbtnCurencyClick == "GBP" ? "green" : "white",
                    color: whichbtnCurencyClick == "GBP" ? "white" : "black",
                  }}
                  onClick={() => filterSGDOrGBP("GBP")}
                  variant="contained"
                  color="error"
                >
                  GBP
                </Button>
                <Button
                  style={{
                    display: "block",
                    backgroundColor: whichbtnCurencyClick == "SGD" ? "green" : "white",
                    color: whichbtnCurencyClick == "SGD" ? "white" : "black",
                  }}
                  onClick={() => filterSGDOrGBP("SGD")}
                  variant="contained"
                  color="error"
                >
                  SGD
                </Button>
              </MDBox>
              <Grid container spacing={6}>
                <Grid item xs={whichbtnCurencyClick != "All" ? 7 : 12}>
                  <MDBox pt={3}>
                    <DataTable
                      table={{
                        columns,
                        rows: rows ? rows : rowscus.filter((item) => item.sell == true),
                      }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Grid>
                {whichbtnCurencyClick != "All" ? (
                  <Grid item xs={5}>
                    <Card sx={{ minWidth: 275 }}>
                      <CardContent>
                        <TextField
                          style={{ width: "100%" }}
                          required
                          id="outlined-required"
                          label="Margins"
                          type="number"
                          value={margin}
                          onChange={(e) => setMargin(e.target.value)}
                        />
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          Range
                        </Typography>
                        <TextField
                          style={{ width: "100%" }}
                          required
                          id="outlined-required"
                          label="min"
                          type="number"
                          value={min}
                          onChange={(e) => setMin(e.target.value)}
                        />
                        <Divider />
                        <TextField
                          style={{ width: "100%" }}
                          required
                          type="number"
                          id="outlined-required"
                          label="max"
                          value={max}
                          onChange={(e) => setMax(e.target.value)}
                        />
                        <div
                          style={{ display: "flex", justifyContent: "center", marginBlock: "15px" }}
                        >
                          <Button
                            onClick={() => handleSubmit()}
                            style={{ color: "white", backgroundColor: "green" }}
                            variant="contained"
                          >
                            save
                          </Button>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </Card>
          </Grid>
        </Grid>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle> Create Orders</DialogTitle>
          <DialogContent>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="user_name"
                  label="user name "
                  fullWidth
                  variant="outlined"
                  value={user_name}
                  onChange={(e) => setuser_name(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="order_date"
                  label="order_date "
                  type="date"
                  fullWidth
                  variant="outlined"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="chat_id"
                  label="chat id "
                  fullWidth
                  variant="outlined"
                  value={chat_id}
                  onChange={(e) => setchat_id(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="contact"
                  label=" contact "
                  fullWidth
                  variant="outlined"
                  value={contact}
                  onChange={(e) => setcontact(e.target.value)}
                />
              </Grid>{" "}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="bank"
                  label=" bank "
                  fullWidth
                  variant="outlined"
                  value={bank}
                  onChange={(e) => setbank(e.target.value)}
                />
              </Grid>{" "}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="bank_no"
                  label="bank no  "
                  fullWidth
                  variant="outlined"
                  value={bank_no}
                  onChange={(e) => setbank_no(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="wallet"
                  label=" wallet "
                  fullWidth
                  variant="outlined"
                  value={wallet}
                  onChange={(e) => setwallet(e.target.value)}
                />
              </Grid>{" "}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="wallet_add"
                  label=" wallet address "
                  fullWidth
                  variant="outlined"
                  value={wallet_add}
                  onChange={(e) => setwallet_add(e.target.value)}
                />
              </Grid>{" "}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="kyc_status"
                  label=" kyc_status "
                  fullWidth
                  variant="outlined"
                  value={kyc_status}
                  onChange={(e) => setkyc_status(e.target.value)}
                />
              </Grid>{" "}
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="active"
                  label=" active "
                  fullWidth
                  variant="outlined"
                />
              </Grid>{" "}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button>Create</Button>
          </DialogActions>
        </Dialog>
      </MDBox>
      <Toaster />

      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
