import { FormLabel } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useGetUsersApi, useUpdateUserApi } from "apis/Users";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../components/Toaster/index";
import { setopenModal } from "../../store/UsersStore/Slice";
export default function FormDialog({ open, setopen, index, data }) {
  const { users, error, oneuser } = useSelector((state) => state.Users);

  const [user_name, setuser_name] = React.useState("");
  const [chat_id, setchat_id] = React.useState("");
  const [contact, setcontact] = React.useState("");
  const [bank, setbank] = React.useState("");
  const [email, setemail] = React.useState("");

  const [bank_no, setbank_no] = React.useState("");
  const [wallet, setwallet] = React.useState("");
  const [wallet_add, setwallet_add] = React.useState("");
  const [kyc_status, setkyc_status] = React.useState("no");
  const [active, setactive] = React.useState(false);
  const { mutate: UpdateUserApi } = useUpdateUserApi();
  const [refresh, setrefresh] = React.useState(false);

  const { data: GetUsersApi, refetch } = useGetUsersApi(refresh);
  useEffect(() => {
    setuser_name(oneuser?.user_name);
    setchat_id(oneuser?.chat_id);
    setactive(oneuser?.active);
    setcontact(oneuser?.contact);
    setkyc_status(oneuser?.kyc_status);
    setemail(oneuser?.email);
  }, [oneuser]);
  const notify = (success) => {
    if (success) {
      toast.success("user updated Successfully !");
    } else {
      toast.error("error!");
    }
  };
  const dispatch = useDispatch();

  const handleClose = () => {
    /*var allmodals = [...open];
    allmodals[index] = false;
    setopen([...allmodals]);*/
    dispatch(setopenModal(false));
  };
  const submitUser = (id) => {
    UpdateUserApi({
      email,
      user_name,
      id,
      kyc_status,
      contact,
      chat_id,
      active,
    });
    setTimeout(() => {
      setrefresh(!refresh);
      handleClose();
    }, 1000);
    notify(true);
  };
  const handleChange = (event) => {
    setactive(event.target.value);
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Edit User</DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={6}>
          <FormLabel style={{fontSize:"14px"}}>User Name</FormLabel>
              <TextField
                autoFocus
                margin="dense"
                id="user_name"
                // label="user name "
                fullWidth
                variant="outlined"
                value={user_name}
                onChange={(e) => setuser_name(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
            <FormLabel style={{fontSize:"14px"}}>Email Address</FormLabel>

              <TextField
                autoFocus
                margin="dense"
                id="name"
                // label=""
                type="email"
                fullWidth
                variant="outlined"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
            <FormLabel style={{fontSize:"14px"}}>chat id </FormLabel>

              <TextField
                autoFocus
                margin="dense"
                id="chat_id"
                // label=""
                fullWidth
                variant="outlined"
                value={chat_id}
                onChange={(e) => setchat_id(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
            <FormLabel style={{fontSize:"14px"}}>Contact</FormLabel>

              <TextField
                autoFocus
                margin="dense"
                id="contact"
                // label=" contact "
                fullWidth
                variant="outlined"
                value={contact}
                onChange={(e) => setcontact(e.target.value)}
              />
            </Grid>{" "}


            <Grid item xs={6}>
              <FormControl fullWidth style={{ marginTop: "10px" }}>
              <FormLabel style={{fontSize:"14px"}}>KYC Status</FormLabel>
                <Select
                  style={{
                    height: "43px",
                  }}
                  value={kyc_status ? kyc_status : ""}
                  label="KYC Status"
                  onChange={(e) => setkyc_status(e.target.value)}
                >
                  <MenuItem value="None">no</MenuItem>
                  <MenuItem value="Tier 1">Tier 1</MenuItem>
                  <MenuItem value="Tier 2">Tier 2</MenuItem>

                </Select>
              </FormControl>
            </Grid>{" "}


            <Grid item xs={6}>
              <FormControl fullWidth style={{ marginTop: "10px" }}>
              <FormLabel style={{fontSize:"14px"}}>active</FormLabel>
                <Select
                  style={{
                    height: "43px",
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={active ? active : ""}
                  label="active"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            </Grid>{" "}

          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button name={oneuser?.id} onClick={(e) => submitUser(e.target.name)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Toaster />
    </div>
  );
}
