import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { useUpdateAdminUserApi, useGetAdminUsersApi } from "apis/Admin_users";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../components/Toaster/index";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useSelector, useDispatch } from "react-redux";
import {usePairs} from '../../apis/Pairs'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  { id: 1, name: "Oliver Hansen" },
  { id: 2, name: "Oliver Hansen" },
];
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function FormDialog({ open, setopen, index, data }) {
  const theme = useTheme();
  const { Groups, error } = useSelector((state) => state.Groups);
  const [referesh, setreferesh] = React.useState(false);
  const [email, setemail] = React.useState(data.email);
  const [user_name, setuser_name] = React.useState("");
  const [password, setpassword] = React.useState(data.password);
  const [first_name, setfirst_name] = React.useState(data.first_name);
  const [last_name, setlast_name] = React.useState(data.last_name);
  const [is_active, setis_active] = React.useState(data.is_active);
  const [is_staff, setis_staff] = React.useState(data.is_staff);
  const [is_verified, setis_verified] = React.useState(data.is_verified);
  const [Permissions, setPermissions] = React.useState([]);
  const [pairs, setPairs] = React.useState(data?.curencies);
  const { mutate: UpdateAdminUserApi } = useUpdateAdminUserApi();
  const { data: GetAdminUsersApi, refetch } = useGetAdminUsersApi(referesh);
  const { data: PairsList, isLoading: pairsLoading } = usePairs();

  const notify = (success) => {
    if (success) {
      toast.success("user updated Successfully !");
    } else {
      toast.error("error!");
    }
  };
  const handleClose = () => {
    var allmodals = [...open];
    allmodals[index] = false;
    setopen([...allmodals]);
    refetch();
  };
  const submitUser = (id) => {
    var pairs_list = [];
    for (var i = 0; i < pairs.length; i++) {
      pairs_list.push(pairs[i].id);
    }
    var permissions = [];
    for (var i = 0; i < Permissions.length; i++) {
      permissions.push(Permissions[i].id);
    }
    var oldper = [];
    for (var m = 0; m < data.groups.length; m++) {
      oldper.push(data.groups[m]);
    }


    UpdateAdminUserApi({
      id,
      email,
      password,
      first_name,
      last_name,
      curencies: pairs_list,
      is_superuser: is_staff ? true : false,
      is_active,
      is_staff,
      is_verified,
      groups: Permissions.length == 0 ? oldper : permissions,
    });
    notify(true);
    handleClose();
    refetch();
    setTimeout(() => {
      handleClose();

      setreferesh(!referesh);
    }, 1000);
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPermissions(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handlePairChange = (event) => {
    const {
      target: { value },
    } = event;
    setPairs(
      typeof value === "string" ? value.split(",") : value
    );
  };


  return (
    <div>
      <Dialog open={open[index] ? open[index] : false} onClose={handleClose}>
        <DialogTitle> update User</DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id=" first name"
                label="first name"
                fullWidth
                variant="outlined"
                value={first_name}
                onChange={(e) => setfirst_name(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="last name"
                label="last name"
                fullWidth
                variant="outlined"
                value={last_name}
                onChange={(e) => setlast_name(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label=" email"
                type="email"
                fullWidth
                variant="outlined"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label=" password"
                type="password"
                fullWidth
                variant="outlined"
                value={password}
                onChange={(e) => setpassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingTop: " 57px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">is active</InputLabel>
                <Select
                  style={{ height: " 41px" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={is_active}
                  label="is active"
                  onChange={(e) => setis_active(e.target.value)}
                >
                  <MenuItem value={true}>yes</MenuItem>
                  <MenuItem value={false}>no</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} style={{ paddingTop: " 57px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">is staff</InputLabel>
                <Select
                  style={{ height: " 41px" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={is_staff}
                  label="is staff"
                  onChange={(e) => setis_staff(e.target.value)}
                >
                  <MenuItem value={true}>yes</MenuItem>
                  <MenuItem value={false}>no</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} style={{ paddingTop: " 57px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"> is_verified</InputLabel>
                <Select
                  style={{ height: " 41px" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={is_verified}
                  label="is verified"
                  onChange={(e) => setis_verified(e.target.value)}
                >
                  <MenuItem value={true}>yes</MenuItem>
                  <MenuItem value={false}>no</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} style={{ paddingTop: " 57px" }}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel id="demo-multiple-chip-label">groups</InputLabel>
                <Select
                  style={{ height: " 41px" }}
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={Permissions}
                  onChange={handleChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => {
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value.id} label={value.name ? value.name : value} />
                        ))}
                      </Box>
                    );
                  }}
                  MenuProps={MenuProps}
                >
                  {Groups?.map((name) => (
                    <MenuItem
                      key={name.id}
                      value={name}
                      style={getStyles(name, Permissions, theme)}
                    >
                      ({name.id}) - {name.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>{" "}
            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel id="demo-multiple-chip-label">Pairs</InputLabel>
                <Select
                  style={{ height: " 41px" }}
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={pairs}
                  onChange={handlePairChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => {
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value.id} label={value.name ? value.name : value} />
                        ))}
                      </Box>
                    );
                  }}
                  MenuProps={MenuProps}
                >
                  {PairsList?.data?.map((pair) => (
                    <MenuItem key={pair.id} value={pair}>
                      {pair.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>{" "}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button name={data.id} onClick={(e) => submitUser(e.target.name)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Toaster />
    </div>
  );
}
