import { createSlice } from "@reduxjs/toolkit";

export const AdminUsersSlice = createSlice({
  name: "admins",
  initialState: {
    AdminUsers: [],
    OneGroup: null,
    error: "",
  },
  reducers: {
    GetAllAdminUsers: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.AdminUsers = action.payload;
    },
    GetOneGroup: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.OneOrder = action.payload;
    },
    errorAtGetAllGroups: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { errorAtGetAllGroups, GetAllAdminUsers, GetOneGroup } = AdminUsersSlice.actions;

export default AdminUsersSlice.reducer;
