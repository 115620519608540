// Telegram bot React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import AdminUsers from "layouts/AdminUsers";
import Groups from "layouts/groups";

import Orders from "layouts/Orders";
import USDT from "layouts/Orders/USDT";
import BTC from "layouts/Orders/BTC";
import USDTMargins from "layouts/Margins/USDT";
import BTCMargins from "layouts/Margins/BTC";

import OrdersDetail from "layouts/Orders/OrderDetail";
import MarginDetail from "layouts/Margins/marginDetail";

import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";

import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Messages from "./layouts/messages/index";
// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  /* {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
 
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },*/
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
    presentOnSideNave: true,
  },

  {
    type: "title",
    title: "super users",
    name: "SuperUsers ",
    route: "/superusers",
    key: "superusers",
    icon: <Icon fontSize="small">table_view</Icon>,
    presentOnSideNave: true,
    collapse: [
      {
        type: "collapse",
        name: "Groups",
        key: "groups",
        icon: <Icon fontSize="small">table_view</Icon>,
        route: "/groups",
        component: <Groups />,
      },
      {
        type: "collapse",
        name: "AdminUsers",
        key: "adminusers",
        icon: <Icon fontSize="small">table_view</Icon>,
        route: "/adminusers",
        component: <AdminUsers />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/users",
    component: <Tables />,
    presentOnSideNave: true,
  },
  {
    type: "collapse",
    name: " messages",
    key: "messages",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/messages",
    component: <Messages />,
    presentOnSideNave: true,
  },
  {
    type: "title",
    title: "orders",
    name: "Orders",
    route: "/orders",
    key: "orders",
    icon: <Icon fontSize="small">table_view</Icon>,
    presentOnSideNave: true,
    collapse: [
      {
        type: "collapse",
        name: "USDT",
        key: "USDT",
        icon: <Icon fontSize="small">table_view</Icon>,
        route: "/USDT",
        component: <USDT />,
      },
      {
        type: "collapse",
        name: "BTC",
        key: "BTC",
        icon: <Icon fontSize="small">table_view</Icon>,
        route: "/BTC",
        component: <BTC />,
      },
    ],
  },
  {
    type: "title",
    title: "Margins",
    name: "Margins",
    route: "/Margins",
    key: "Margins",
    icon: <Icon fontSize="small">table_view</Icon>,
    presentOnSideNave: true,
    collapse: [
      {
        type: "collapse",
        name: "BTC",
        key: "margins_btc",
        icon: <Icon fontSize="small">table_view</Icon>,
        route: "/margins_btc",
        component: <BTCMargins />,
      },
      {
        type: "collapse",
        name: "USDT",
        key: "margins_usdt",
        icon: <Icon fontSize="small">table_view</Icon>,
        route: "/margins_usdt",
        component: <USDTMargins />,
      },
    ],
  },
  {
    type: "collapse",
    name: "orderDetails",
    key: "orderDetails",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/orders/detail",
    component: <OrdersDetail />,
    presentOnSideNave: false,
  },
  {
    type: "collapse",
    name: "MarginDetail",
    key: "MarginDetail",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/margin/edit",
    component: <MarginDetail />,
    presentOnSideNave: false,
  },

  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    presentOnSideNave: false,
  },
  /*{
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
    presentOnSideNave: false,
  },*/
];

export default routes;
