import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { login, errorAtLogin } from "../layouts/authentication/AuthStore/Slice";
import { errorAtGetAllUsers, GetAllUsers, GetOneUser } from "store/UsersStore/Slice";
import { BASE_URL } from "./BaseURL";



const getUserRequest = async () => {
  const items = JSON.parse(localStorage.getItem("user"));

  return await axios.get(`${BASE_URL}/api/v1/bot_users`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};

const getOneUserRequest = async (id) => {
  const items = JSON.parse(localStorage.getItem("user"));

  return await axios.get(`${BASE_URL}/api/v1/bot_users?id=${id}`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};

const PostCreateUserrequest = async (data) => {
  const items = JSON.parse(localStorage.getItem("user"));

  return await axios.post(`${BASE_URL}/api/v1/bot_users`, data, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const PutChangeUsers = async (data) => {
  const items = JSON.parse(localStorage.getItem("user"));
  return await axios.put(`${BASE_URL}/api/v1/bot_users/${data.id}`, data, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const PostDeleteUserrequest = async (id) => {
  const items = JSON.parse(localStorage.getItem("user"));

  return await axios.delete(`${BASE_URL}/api/v1/bot_users/${id}`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const useUpdateUserApi = () => {
  return useMutation(PutChangeUsers, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };

      return result;
    },
    onError: (err) => {
      return err;
    },
  });
};
const useGetUsersApi = (refresh) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useQuery(["users", refresh], getUserRequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };

      dispatch(GetAllUsers(result.data));

      return result;
    },
    onError: (err) => {
      //  return err;
    },
  });
};
const useCreateUserApi = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useMutation(PostCreateUserrequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      //  dispatch(login(result));
      // return result;
    },
    onError: (err) => {
      // dispatch(errorAtGetAllUsers(err.response));
      //  return err;
    },
  });
};
const useOneUserApi = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useMutation(getOneUserRequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      dispatch(GetOneUser(result.data[0]));
      // return result;
    },
    onError: (err) => {
      // dispatch(errorAtGetAllUsers(err.response));
      //  return err;
    },
  });
};
const useDeleteUserApi = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useMutation(PostDeleteUserrequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      //  dispatch(login(result));
      // return result;
    },
    onError: (err) => {
      // dispatch(errorAtGetAllUsers(err.response));
      //  return err;
    },
  });
};
export { useGetUsersApi, useCreateUserApi, useUpdateUserApi, useDeleteUserApi, useOneUserApi };
