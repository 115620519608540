import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

import MDBox from "components/MDBox";
import Button from "@mui/material/Button";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Posts from "./posts";
import backgroundImage from "assets/images/bot2.webp";
import TextField from "@mui/material/TextField";
import { useCreatePostApi } from "apis/Posts";
import { useGetOneOrderApi } from "apis/Orders";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../components/Toaster/index";
import { useupdatemarginApi, useGetOneMarginApi } from "../../apis/margins";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

function Index() {
  const [searchParams] = useSearchParams();
  const { OneMargin } = useSelector((state) => state.Margins);
  const navigate = useNavigate();

  //eOrder} =useGetOneOrderApi()
  const [header, setheader] = useState("");
  const [text, settext] = useState("");
  const [chat_id, setchat_id] = useState("");

  const [value, setValue] = useState("Controlled");
  const { mutate: createPostApi } = useCreatePostApi();
  const [currency, setcurrency] = useState("SGD");

  const [type, settype] = useState("sell");
  const [typeofcoin, settypeofcoin] = useState("USDT");
  const [margin, setmargin] = useState("");
  const [max, setmax] = useState("");
  const [min, setmin] = useState("");
  const { mutate: putDataApi } = useupdatemarginApi();
  const { mutate: getOneMargin } = useGetOneMarginApi();
  const notify = (success) => {
    if (success) {
      toast.success("margin updated Successfully !");
    } else {
      toast.error("error!");
    }
  };
  useEffect(() => {
    getOneMargin({ id: searchParams.get("id") });
  }, []);
  useEffect(() => {
    setcurrency(OneMargin?.currency);
    setmax(OneMargin?.sup_limit_range);
    setmin(OneMargin?.inf_limit_range);
    setmargin((OneMargin?.margin * 100).toFixed(2));
    settype(OneMargin?.buy == true ? "buy" : "sell");
    settypeofcoin(
      OneMargin?.get_pair == "BTC/SGD" || OneMargin?.get_pair == "BTC/GBP" ? "BTC" : "USDT"
    );
  }, [OneMargin]);
  const handlesubmit = (event) => {
    putDataApi({
      id: searchParams.get("id"),
      sup_limit_range: max,
      inf_limit_range: min,
      margin: margin / 100,
      buy: type == "buy" ? true : false,
      sell: type == "sell" ? true : false,
      btc: typeofcoin == "BTC" ? true : false,
      usdt: typeofcoin == "USDT" ? true : false,
      currency: currency,
    });
    notify(true);
    setTimeout(() => {
      navigate(-1);
    }, 2000);
  };

  /*useEffect(()=>{
const data= getOneOrder({id:39})
},[])*/
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={1} alignItems="self-end">
            <Grid item xs={12} md={12} lg={12}>
              <Typography style={{ marginBottom: "15px" }}> margin</Typography>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">currency</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={currency}
                      label="currency"
                      style={{ padding: "10px" }}
                      onChange={(e) => setcurrency(e.target.value)}
                    >
                      <MenuItem value={"SGD"}>SGD</MenuItem>
                      <MenuItem value={"GBP"}>GBP</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={type}
                      label="type"
                      style={{ padding: "10px" }}
                      onChange={(e) => settype(e.target.value)}
                    >
                      <MenuItem value={"buy"}>buy</MenuItem>
                      <MenuItem value={"sell"}>sell</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">type of coin</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={typeofcoin}
                      label="type of coin"
                      style={{ padding: "10px" }}
                      onChange={(e) => settypeofcoin(e.target.value)}
                    >
                      <MenuItem value={"USDT"}>USDT</MenuItem>
                      <MenuItem value={"BTC"}>BTC</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    style={{ padding: "0px 0px" }}
                    margin="dense"
                    id="user_id"
                    value={margin}
                    label="margin  "
                    fullWidth
                    type="number"
                    variant="outlined"
                    onChange={(e) => setmargin(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    style={{ padding: "0px 0px" }}
                    type="number"
                    margin="dense"
                    id="user_id"
                    value={min}
                    label="min  "
                    fullWidth
                    variant="outlined"
                    onChange={(e) => setmin(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    style={{ padding: "0px 0px" }}
                    type="number"
                    margin="dense"
                    id="user_id"
                    value={max}
                    label="max  "
                    fullWidth
                    variant="outlined"
                    onChange={(e) => setmax(e.target.value)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ margin: "auto", display: "flex", justifyContent: "center" }}
                >
                  <Button
                    onClick={handlesubmit}
                    style={{ width: "50%", margin: "auto", color: "white", marginBlock: "15px" }}
                    variant="contained"
                  >
                    save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <Toaster />
      <Footer />
    </DashboardLayout>
  );
}

export default Index;
