import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setfilterRedux } from "./store/OrdersStore/Slice";

const Protected = ({ isLoggedIn, children }) => {
  const Dispatch = useDispatch();

  if (!isLoggedIn) {
    return <Navigate to="/authentication/sign-in" replace />;
  }
  return <div onClick={() => Dispatch(setfilterRedux("All"))}>{children}</div>;
};
export default Protected;
