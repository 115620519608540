import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import './style.css';
// Telegram bot React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useSelector } from "react-redux";
import Modal from "./modal";

// Telegram bot React example components
import Button from "@mui/material/Button";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import * as React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../components/Toaster/index";
// Data
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useCreateUserApi, useGetUsersApi } from "apis/Users";
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";


function Tables() {
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [refresh, setrefresh] = React.useState(false);
  const [email, setemail] = React.useState(null);
  const [user_name, setuser_name] = React.useState(null);
  const [chat_id, setchat_id] = React.useState(null);
  const [contact, setcontact] = React.useState(null);
  const [bank, setbank] = React.useState(null);
  const [bank_no, setbank_no] = React.useState(null);
  const [wallet, setwallet] = React.useState(null);
  const [wallet_add, setwallet_add] = React.useState(null);
  const [kyc_status, setkyc_status] = React.useState("None");
  const [active, setactive] = React.useState(false);
  const { mutate: CreateUserApi } = useCreateUserApi();
  const [rows, setfilterRows] = React.useState([]);
  const [whichbtnClick, setwhichbtnClick] = React.useState("whitelist");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    setactive(event.target.value);
  };
  const { users, error, openModal } = useSelector((state) => state.Users);

  const { columns, rows: rowscus } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const { data: GetUsersApi } = useGetUsersApi();
  
  useEffect(() => {
    let filted = rowscus.filter((item) => item.active == true);
    //    console.log(rowscus[1].active, "rowscusrowscus");
    setfilterRows(filted);
  }, []);

  useEffect(() => {
    let filted = rowscus?.filter((item) =>  item.author.props.name.toLowerCase().includes(search.toLowerCase()))
    setfilterRows(filted);
  }, [search]);



  const filterwhiteAndBlack = (arg) => {
    if (arg == "whitelist") {
      setwhichbtnClick("whitelist");
      let filted = rowscus.filter((item) => item.active === true);
      setfilterRows(filted);
    } else {
      let filted = rowscus.filter((item) => item.active !== true);
      setfilterRows(filted);
      setwhichbtnClick("blacklist");
    }
  };
  const notify = (success) => {
    if (success) {
      toast.success("user created Successfully !");
    } else {
      toast.error("error!");
    }
  };
  useEffect(() => {}, []);
  const submitUser = () => {
    CreateUserApi({
      email,
      user_name,

      kyc_status,

      contact,
      chat_id,
      active,
    });
    if (!email || !user_name || !kyc_status || !contact || !chat_id || !active) {
      notify(false);
    } else {
      notify(true);
    }
    handleClose();
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDTypography variant="h6" color="white">
                  Users
                </MDTypography>
                <Button onClick={handleClickOpen} variant="contained" color="error">
                  Create
                </Button>
              </MDBox>
              <MDBox
                mx={2}
                mt={1}
                py={3}
                px={2}
                variant="gradient"
                bgColor="transparent"
              >
                

              <MDBox className="page-header-nav">
                <MDBox className='btns-section'>
                  <Button
                    style={{
                      // display: "block",
                      marginInline: "10px",
                      backgroundColor: whichbtnClick == "whitelist" ? "green" : "white",
                      color: whichbtnClick == "whitelist" ? "white" : "black",
                    }}
                    onClick={() => filterwhiteAndBlack("whitelist")}
                    variant="contained"
                    color="error"
                  >
                    WhiteList
                  </Button>

                  <Button
                    style={{
                      // display: "block",
                      backgroundColor: whichbtnClick != "whitelist" ? "green" : "white",
                      color: whichbtnClick != "whitelist" ? "white" : "black",
                    }}
                    onClick={() => filterwhiteAndBlack("blacklist")}
                    variant="contained"
                    color="error"
                  >
                    BlackList
                  </Button>
                </MDBox>
                <TextField
                  className="search-box"
                  autoFocus
                  margin="dense"
                  id="search"
                  label="Search ..."
                  fullWidth
                  variant="outlined"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </MDBox>

              </MDBox>
              <MDBox pt={3}>

                <DataTable
                  table={{
                    columns,
                    rows: rows.length > 0 ? rows : rowscus.filter((item) => item.active === true),
                  }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle> Create User</DialogTitle>
          <DialogContent>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="user_name"
                  label="user name "
                  fullWidth
                  variant="outlined"
                  value={user_name}
                  onChange={(e) => setuser_name(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="outlined"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="chat_id"
                  label="chat id "
                  fullWidth
                  variant="outlined"
                  value={chat_id}
                  onChange={(e) => setchat_id(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="contact"
                  label=" contact "
                  fullWidth
                  variant="outlined"
                  value={contact}
                  onChange={(e) => setcontact(e.target.value)}
                />
              </Grid>{" "}
              <Grid item xs={6}>
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <InputLabel id="demo-simple-select-label">kyc_status</InputLabel>
                  <Select
                    style={{
                      height: "43px",
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={kyc_status}
                    label="kyc_status"
                    onChange={(e) => setkyc_status(e.target.value)}
                  >
                    <MenuItem value={"None"}>None</MenuItem>

                    <MenuItem value={"Tier 1"}>Tier 1</MenuItem>
                    <MenuItem value={"Tier 2"}>Tier 2</MenuItem>
                  </Select>
                </FormControl>
              </Grid>{" "}
              <Grid item xs={6}>
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                  <InputLabel id="demo-simple-select-label">active</InputLabel>
                  <Select
                    style={{
                      height: "43px",
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={active}
                    label="active"
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>true</MenuItem>
                    <MenuItem value={false}>false</MenuItem>
                  </Select>
                </FormControl>
              </Grid>{" "}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={(e) => submitUser()}>Create</Button>
          </DialogActions>
        </Dialog>
      </MDBox>
      <Toaster />
      <Modal open={openModal} />

      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
