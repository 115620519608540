import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { login, errorAtLogin } from "../layouts/authentication/AuthStore/Slice";
import { GetOneGroup } from "store/GroupsSlice/Slice";
import { GetAllAdminUsers } from "store/AdminUserSlice/Slice";
import { BASE_URL } from "./BaseURL";


const getAdminUsersRequest = async () => {
  const items = JSON.parse(localStorage.getItem("user"));

  return await axios.get(`${BASE_URL}/api/v1/admin_users`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const getoneGrouprequest = async ({ id }) => {
  
  const items = JSON.parse(localStorage.getItem("user"));
  return await axios.get(`${BASE_URL}/api/v1/admin_users?id=${id}`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const PostCreateAdminUsersrequest = async (data) => {
  const items = JSON.parse(localStorage.getItem("user"));

  return await axios.post(`${BASE_URL}/api/v1/admin_users`, data, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const PutChangeAdminUser = async (data) => {
  const items = JSON.parse(localStorage.getItem("user"));
  return await axios.put(`${BASE_URL}/api/v1/admin_users/${data.id}`, data, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const PostDeleteAdminUsersrequest = async (id) => {
  const items = JSON.parse(localStorage.getItem("user"));
  return await axios.delete(`${BASE_URL}/api/v1/admin_users/${id}`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const useUpdateAdminUserApi = () => {
  return useMutation(PutChangeAdminUser, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      return result;
    },
    onError: (err) => {
      return err;
    },
  });
};
const useGetAdminUsersApi = (refresh) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useQuery(["get_Admins", refresh], getAdminUsersRequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      dispatch(GetAllAdminUsers(result.data));

      return result;
    },
    onError: (err) => {
      //  return err;
    },
  });
};
const useGetOneGroupApi = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useMutation(getoneGrouprequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      dispatch(GetOneGroup(result.data[0]));

      // return result;
    },
    onError: (err) => {
      // dispatch(errorAtGetAllUsers(err.response));
      //  return err;
    },
  });
};
const useCreateAdminUserApi = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useMutation(PostCreateAdminUsersrequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      //  dispatch(login(result));
      // return result;
    },
    onError: (err) => {
      // dispatch(errorAtGetAllUsers(err.response));
      //  return err;
    },
  });
};
const useDeleteAdminUsersApi = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useMutation(PostDeleteAdminUsersrequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      //  dispatch(login(result));
      // return result;
    },
    onError: (err) => {
      // dispatch(errorAtGetAllUsers(err.response));
      //  return err;
    },
  });
};
export {
  useGetOneGroupApi,
  useCreateAdminUserApi,
  useUpdateAdminUserApi,
  useDeleteAdminUsersApi,
  useGetAdminUsersApi,
};
