import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  useChangeStatusApi, useDeletemanyOrderApi, useDeleteOrderApi,
  useGetOrdersApi
} from "apis/Orders";
import Toaster from "components/Toaster/index";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setfilterRedux } from "../../../store/OrdersStore/Slice";
import Modal from "../modal";

export default function data() {
  const [open2, setOpen2] = useState([]);

  const { mutate: updateOrderStatusApi } = useChangeStatusApi();
  const { isLoading, error, mutate: DeleteOrderApi } = useDeleteOrderApi();
  const [refresh, setrefresh] = useState(false);

  const { orders, updateErrors, deleteErrors } = useSelector((state) => state.Orders);
  const [filterDate, setFilterDate] = useState([]);
  const { data: GetOrdersApi, refetch } = useGetOrdersApi(refresh);
  const [checked, setchecked] = useState({});
  const { mutate: DeleteManyOrderApi } = useDeletemanyOrderApi();

  useEffect(() => {
    let arrayofCheckbox = {};
    for (let order in orders) {
      arrayofCheckbox[`${orders[order].id}`] = false;
    }
    console.log(arrayofCheckbox, "arrayofCheckbox");
    setchecked(arrayofCheckbox);
  }, []);
  useEffect(() => {
    console.log(checked, "checked");
  }, [checked]);

  const handleChange = (e) => {
    //const filter = checked.filter((item) => item.id == e.target.id);
    setchecked((prev) => {
      return { ...checked, [`${e.target.id}`]: !prev[`${e.target.id}`] };
    });
  };
  const dispatch = useDispatch();

  const HandleupdateStatus = (id, status, chat_id) => {
    var logicstatus =
      status == "pending"
        ? "approved"
        : status == "approved"
        ? "paid"
        : status == "paid"
        ? "completed"
        : status == "canceled"
        ? "canceled"
        : "pending";
    updateOrderStatusApi({ id, status: logicstatus, chat_id, send_update_status_notif: true });

    setTimeout(() => {
      setrefresh(!refresh);
    }, 500);
    setTimeout(() => {
      dispatch(setfilterRedux("All"));
    }, 500);
  };

  useEffect(() => {
    var filter = orders?.filter((item) => item.pair == "BTC/SGD" || item.pair == "BTC/GBP");
    setFilterDate(filter);
  }, [orders]);
  useEffect(() => {
    var numofmodals = [];
    for (var i = 0; i < filterDate?.length; i++) {
      numofmodals.push(false);
    }
    setOpen2([...numofmodals]);
  }, [filterDate]);
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );
  const handleClickOpen2 = (modalvalue, index) => {
    var allmodals = [...open2];

    allmodals[index] = modalvalue;
    setOpen2(allmodals);
  };
  useEffect(() => {}, [orders, open2]);
  const handleClose2 = () => {
    setOpen2(false);
  };
  const renderToaster = () => {
    if (true) {
      return <Toaster success={true} />;
    } else {
      return;
    }
  };
  const notify = (success, message) => {
    if (success) {
      toast.success(message);
    } else {
      toast.error(message);
    }
  };
  const Data = () => {
    let arrays = [];
    if (filterDate) {
      for (var i = 0; i < filterDate?.length; i++) {
        arrays.push({
          id: filterDate[i].id,
          fiat_value: filterDate[i].fiat_value,
          crypto_value: filterDate[i].crypto_value,

          is_buy: filterDate[i].is_buy == "1" ? "buy" : "sell",
          status: filterDate[i].status,
          chat_id: filterDate[i].chat_id,
          author: (
            <Link to={`/orders/detail?id=${filterDate[i].id}`}>
              <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                {filterDate[i].id}
              </MDTypography>
            </Link>
          ),
          function: (
            <Link to={`/orders/detail?id=${filterDate[i].id}`}>
              <Job title={filterDate[i].chat_id} description="" />{" "}
            </Link>
          ),
          Date: (
            <Link to={`/orders/detail?id=${filterDate[i].id}`}>
              <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                {filterDate[i].order_date}
              </MDTypography>
            </Link>
          ),
          employed: (
            <Link to={`/orders/detail?id=${filterDate[i].id}`}>
              <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                {filterDate[i].pair}
              </MDTypography>
            </Link>
          ),
          Crypto: (
            <Link to={`/orders/detail?id=${filterDate[i].id}`}>
              <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                {filterDate[i].crypto_value}
              </MDTypography>
            </Link>
          ),
          Fiat: (
            <Link to={`/orders/detail?id=${filterDate[i].id}`}>
              <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                {filterDate[i].fiat_value}
              </MDTypography>
            </Link>
          ),
          Status: (
            <Link to={`/orders/detail?id=${filterDate[i].id}`}>
              <MDBox ml={0}>
                <MDBadge
                  badgeContent={filterDate[i].status}
                  color={
                    filterDate[i].status == "pending"
                      ? "dark"
                      : filterDate[i].status == "canceled"
                      ? "red"
                      : "success"
                  }
                  variant="gradient"
                  size="sm"
                  style={{}}
                />
              </MDBox>
            </Link>
          ),
          action: (
            <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
              <Button
                /*onClick={(e) => {
                  handleClickOpen2(true, e.target.id);
                }}*/
                onClick={(e) => {
                  let id = filterDate[i]?.id;
                  HandleupdateStatus(
                    e.target.id,
                    e.target.name,
                    e.target.attributes.getNamedItem("data-chat_id").value
                  );
                  if (updateErrors) {
                    notify(false, updateErrors?.detail);
                  } else {
                    notify(true, "order updated Successfully !");
                  }
                }}
                style={{
                  backgroundColor: "#4caf50",
                  color: "white",
                  marginInline: "5px",
                  display: filterDate[i].status == "canceled" ? "none" : "inline-block",
                }}
                variant="contained"
                data-chat_id={filterDate[i].chat_id}
                id={filterDate[i].id}
                name={filterDate[i].status}
              >
                {filterDate[i].status == "pending"
                  ? "approve"
                  : filterDate[i].status == "canceled"
                  ? "canceled"
                  : filterDate[i].status == "approved"
                  ? "paid"
                  : filterDate[i].status == "paid"
                  ? "complete"
                  : filterDate[i].status}
              </Button>
              <Button
                style={{
                  backgroundColor: "#f44336",
                  color: "white",
                  display: filterDate[i].status == "canceled" ? "inline-block" : "inline-block",
                }}
                variant="contained"
                id={filterDate[i]?.id}
                data-chat_id={filterDate[i].chat_id}
                data-status={filterDate[i]?.status}
                onClick={(e) => {
                  if (
                    e.target.attributes.getNamedItem("data-status").value != "canceled" &&
                    e.target.attributes.getNamedItem("data-status").value != "completed"
                  ) {
                    HandleupdateStatus(
                      e.target.id,
                      "canceled",
                      e.target.attributes.getNamedItem("data-chat_id").value
                    );
                    if (deleteErrors) {
                      notify(false, deleteErrors?.detail);
                    } else {
                      notify(true, "order canceled Successfully !");
                    }
                  } else {
                    DeleteOrderApi(e.target.id);
                    setTimeout(() => {
                      setrefresh(!refresh);
                    }, 500);
                    if (deleteErrors) {
                      notify(false, deleteErrors?.detail);
                    } else {
                      notify(true, "order deleted Successfully !");
                    }
                  }
                }}
              >
                {filterDate[i].status == "canceled" || filterDate[i].status == "completed"
                  ? "Delete"
                  : "Cancel"}
              </Button>
              <Checkbox
                checked={checked[filterDate[i].id]}
                onChange={handleChange}
                id={filterDate[i].id}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Button
                style={{
                  backgroundColor: "#f44336",
                  color: "white",
                  display: Object.values(checked).some((value) => value === true)
                    ? "inline-block"
                    : "none",
                }}
                onClick={() => {
                  DeleteManyOrderApi(checked);
                  window.location.reload(true);
                }}
              >
                Delete All
              </Button>
              <Toaster />

              <Modal open={open2} data={filterDate[i]} index={i} setopen={setOpen2} />
            </MDTypography>
          ),
        });
      }
    }

    return arrays;
  };
  return {
    columns: [
      { Header: "Order ID", accessor: "author", align: "left" },
      { Header: "User ID", accessor: "function", align: "left" },
      { Header: "Date", accessor: "Date", align: "center" },
      { Header: "Pair", accessor: "employed", align: "center" },
      { Header: "Crypto", accessor: "Crypto", align: "center" },
      { Header: "Fiat", accessor: "Fiat", align: "center" },
      { Header: "Status", accessor: "Status", align: "center" },

      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: orders ? Data().reverse() : [],
  };
}
