import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { login, errorAtLogin } from "../layouts/authentication/AuthStore/Slice";
import {
  errorAtGetAllOrders,
  GetAllOrders,
  GetOneOrder,
  deleteError,
  updateError,
} from "store/OrdersStore/Slice";
import { BASE_URL } from "./BaseURL";


const getOrderRequest = async () => {
  const items = JSON.parse(localStorage.getItem("user"));

  return await axios.get(`${BASE_URL}/api/v1/orders`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};

const PostChangeStatus = async (data) => {
  const items = JSON.parse(localStorage.getItem("user"));
  return await axios.put(
    `${BASE_URL}/api/v1/orders/${data.id}`,
    { ...data },
    {
      headers: {
        Authorization: `token ${items.token}`,
      },
    }
  );
};
const PostDeleteOrdersrequest = async (id) => {
  const items = JSON.parse(localStorage.getItem("user"));
  return await axios.delete(`${BASE_URL}/api/v1/orders/${id}`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const PostDeletemanyOrdersrequest = async (data) => {
  const items = JSON.parse(localStorage.getItem("user"));
  var keys = Object.keys(data);

  var filtered = keys.filter(function (key) {
    return data[key];
  });
  const arrOfNum = filtered.map((str) => {
    return Number(str);
  });
  const body = { ids: arrOfNum };
  console.log(filtered, "filtered");
  return await axios.delete(`${BASE_URL}/api/v1/orders`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
    data: { ids: arrOfNum },
  });
};
const getoneOrdersrequest = async ({ id }) => {
  const items = JSON.parse(localStorage.getItem("user"));
  return await axios.get(`${BASE_URL}/api/v1/orders?id=${id}`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const useChangeStatusApi = () => {
  const dispatch = useDispatch();

  return useMutation(PostChangeStatus, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };

      return result;
    },
    onError: (err) => {
      console.log(err.response.data, "errrrrrrr");

      dispatch(updateError(err.response.data));

      return err;
    },
  });
};
const useGetOrdersApi = (refresh) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useQuery(["orders", refresh], getOrderRequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      dispatch(GetAllOrders(result.data));

      return result;
    },
    onError: (err) => {
      //  return err;
    },
  });
};
const useDeleteOrderApi = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useMutation(PostDeleteOrdersrequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      //  dispatch(login(result));

      // return result;
    },
    onError: (err) => {
      dispatch(deleteError(err.response.data));

      // dispatch(errorAtGetAllUsers(err.response));
      //  return err;
    },
  });
};
const useDeletemanyOrderApi = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useMutation(PostDeletemanyOrdersrequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      //  dispatch(login(result));

      // return result;
    },
    onError: (err) => {
      dispatch(deleteError(err.response.data));

      // dispatch(errorAtGetAllUsers(err.response));
      //  return err;
    },
  });
};
const useGetOneOrderApi = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useMutation(getoneOrdersrequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      dispatch(GetOneOrder(result.data[0]));

      // return result;
    },
    onError: (err) => {
      // dispatch(errorAtGetAllUsers(err.response));
      //  return err;
    },
  });
};

export {
  useGetOrdersApi,
  useChangeStatusApi,
  useDeleteOrderApi,
  useGetOneOrderApi,
  useDeletemanyOrderApi,
};
