import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { useUpdateGroupApi, useGetGroupsApi } from "apis/Groups";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";

import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useSelector, useDispatch } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  { id: 1, name: "Oliver Hansen" },
  { id: 2, name: "Oliver Hansen" },
];
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function FormDialog({ open, setopen, index, data }) {
  const [user_name, setuser_name] = React.useState(data.name);
  const theme = useTheme();
  const [Permissions, setPermissions] = React.useState([]);
  const { Groups, error, PermissionsData } = useSelector((state) => state.Groups);

  const { mutate: UpdateGroupApi } = useUpdateGroupApi();
  const { data: GetGroupsApi, refetch } = useGetGroupsApi();

  const handleClose = () => {
    var allmodals = [...open];
    allmodals[index] = false;
    setopen([...allmodals]);
    refetch();
  };
  const submitUser = (id) => {
    var permissions = [];
    for (var i = 0; i < Permissions.length; i++) {
      permissions.push(Permissions[i].id);
    }
    var oldper = [];
    for (var m = 0; m < data.permissions.length; m++) {
      oldper.push(data.permissions[m]);
    }

    UpdateGroupApi({
      id,
      name: user_name,
      permissions: Permissions.length == 0 ? data.permissions : permissions,
    });
    //handleClose();
    refetch();
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPermissions(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <div>
      <Dialog open={open[index] ? open[index] : false} onClose={handleClose}>
        <DialogTitle> Create User</DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                margin="dense"
                id="user_name"
                label=" name"
                fullWidth
                variant="outlined"
                value={user_name}
                onChange={(e) => setuser_name(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel id="demo-multiple-chip-label">Permissions</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  style={{ minHeight: "40px" }}
                  value={Permissions}
                  onChange={handleChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value.id} label={value.name} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {PermissionsData?.map((name) => (
                    <MenuItem
                      key={name.id}
                      value={name}
                      style={getStyles(name, Permissions, theme)}
                    >
                      {name.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>{" "}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button name={data.id} onClick={(e) => submitUser(e.target.name)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
