import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { login, errorAtLogin } from "../layouts/authentication/AuthStore/Slice";
import { GetAllGroups, GetOneGroup, GetAllPermissions } from "store/GroupsSlice/Slice";
import { BASE_URL } from "./BaseURL";


const getGroupsRequest = async () => {
  const items = JSON.parse(localStorage.getItem("user"));

  return await axios.get(`${BASE_URL}/api/v1/groups`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const getPermissionsRequest = async () => {
  const items = JSON.parse(localStorage.getItem("user"));

  return await axios.get(`${BASE_URL}/api/v1/permissions`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const getoneGrouprequest = async ({ id }) => {
  const items = JSON.parse(localStorage.getItem("user"));
  return await axios.get(`${BASE_URL}/api/v1/groups?id=${id}`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const PostCreateGrouprequest = async (data) => {
  const items = JSON.parse(localStorage.getItem("user"));

  return await axios.post(`${BASE_URL}/api/v1/post_groups`, data, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const PutChangeGroup = async (data) => {
  const items = JSON.parse(localStorage.getItem("user"));
  return await axios.put(`${BASE_URL}/api/v1/groups/${data.id}`, data, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const PostDeleterouprequest = async (id) => {
  const items = JSON.parse(localStorage.getItem("user"));
  return await axios.delete(`${BASE_URL}/api/v1/groups/${id}`, {
    headers: {
      Authorization: `token ${items.token}`,
    },
  });
};
const useUpdateGroupApi = () => {
  return useMutation(PutChangeGroup, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      return result;
    },
    onError: (err) => {
      return err;
    },
  });
};
const useGetGroupsApi = (refresh) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useQuery(["get_groups", refresh], getGroupsRequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      dispatch(GetAllGroups(result.data));

      return result;
    },
    onError: (err) => {
      //  return err;
    },
  });
};

const useGetPermissionsApi = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useQuery("getPermissionsRequest", getPermissionsRequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      dispatch(GetAllPermissions(result.data));

      return result;
    },
    onError: (err) => {
      //  return err;
    },
  });
};

const useGetOneGroupApi = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useMutation(getoneGrouprequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      dispatch(GetOneGroup(result.data[0]));

      // return result;
    },
    onError: (err) => {
      // dispatch(errorAtGetAllUsers(err.response));
      //  return err;
    },
  });
};
const useCreateGroupApi = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useMutation(PostCreateGrouprequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      //  dispatch(login(result));
      // return result;
    },
    onError: (err) => {
      // dispatch(errorAtGetAllUsers(err.response));
      //  return err;
    },
  });
};
const useDeleteGroupApi = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return useMutation(PostDeleterouprequest, {
    onSuccess: (res) => {
      const result = {
        status: res.status + "-" + res.statusText,
        headers: res.headers,
        data: res.data,
      };
      //  dispatch(login(result));
      // return result;
    },
    onError: (err) => {
      // dispatch(errorAtGetAllUsers(err.response));
      //  return err;
    },
  });
};
export {
  useGetGroupsApi,
  useGetOneGroupApi,
  useCreateGroupApi,
  useUpdateGroupApi,
  useDeleteGroupApi,
  useGetPermissionsApi,
};
