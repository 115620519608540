import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useChangeStatusApi, useGetOneOrderApi } from "apis/Orders";
import { useCreatePostApi } from "apis/Posts";
import backgroundImage from "assets/images/bot2.webp";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../components/Toaster/index";
import "./style.css";



function Index() {
  let navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { OneOrder } = useSelector((state) => state.Orders);
  const { mutate: updateOrderApi } = useChangeStatusApi();

  const { mutate: getOneOrder } = useGetOneOrderApi();
  const [header, setheader] = useState(null);
  const [text, settext] = useState(null);
  const [chat_id, setchat_id] = useState(null);
  const [user_name, setuser_name] = useState("");
  const [order_date, setorder_date] = useState("");
  const [note, setnote] = useState("");
  const [bank, setbank] = useState("");
  const [bank_no, setbank_no] = useState("");
  const [wallet, setwallet] = useState("");
  const [wallet_ad, setwallet_ad] = useState("");
  const [bank_sort_number, setbank_sort_number] = useState("");
  const [bankRegisteredName, setbankRegisteredName] = useState("");
  const [network, setNetwork] = useState("");
  const [is_buy, setIs_buy] = useState(false);

  const [kyc_status, setkyc_status] = useState("");
  const [pair, setpair] = useState("");
  const [crypto, setcrypto] = useState(null);
  const [data, setdata] = useState("");

  const [fiat, setfiat] = useState("");

  const [value, setValue] = useState("Controlled");
  const { mutate: createPostApi } = useCreatePostApi();
  const notify = (success, message) => {
    if (success) {
      toast.success(message);
    } else {
      toast.error("error!");
    }
  };
  const handlesubmit = (event) => {
    setValue(event.target.value);
    createPostApi({ header, text, chat_id });
    if (!text || !header || !chat_id) {
      notify(false, "error");
    } else {
      notify(true, "message sent Successfully !");
    }
  };
  const handleUpdate = (event) => {
    // createPostApi({ header, text, chat_id });
    const data = {
      chat_id,
      id: user_name,
      bank: bank,
      bank_registred_name:bankRegisteredName,
      status: kyc_status,
      note,
      order_date,
      pair,
      bank_no,

      crypto_value: crypto,
      fiat_value: fiat,
      wallet,
      wallet_add: wallet_ad,
      network:network,
      bank_sort_number,
      send_update_status_notif: false,
    };
    updateOrderApi(data);

    notify(true, "order updated Successfully");
    setTimeout(() => {
      navigate(-1);
    }, 500);
  };

  useEffect(() => {
    const data = getOneOrder({ id: searchParams.get("id") });
    setdata(data);
  }, []);
  useEffect(() => {
    setuser_name(OneOrder.id);
    setbank(OneOrder.bank);
    setwallet(OneOrder.wallet);
    setwallet_ad(OneOrder.wallet_add);
    setbank_sort_number(OneOrder.bank_sort_number);
    setbankRegisteredName(OneOrder.bank_registred_name);
    setbank_no(OneOrder.bank_no);
    setchat_id(OneOrder.chat_id);
    setorder_date(OneOrder.order_date);
    setIs_buy(OneOrder.is_buy);
    setNetwork(OneOrder.network);
    setnote(OneOrder.note);
    setpair(OneOrder.pair);
    setfiat(OneOrder.fiat_value);
    setcrypto(OneOrder.crypto_value);
    setkyc_status(OneOrder.status);
  }, [OneOrder]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />

      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          {OneOrder ? (
            <Grid container spacing={1} alignItems="self-start">
              <Grid item xs={12} md={7} lg={7}>
                <Typography style={{ marginBottom: "15px" }}>
                  {OneOrder?.is_buy == 0 ?
                  <>Order <span class="badge badge-danger">Sell</span></>
                  : 
                  <>Order <span class="badge badge-success">Buy</span></>
                  }

                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="user_id"
                      value={user_name}
                      label="Order id  "
                      fullWidth
                      variant="outlined"
                      //  onChange={(e) => setuser_name(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="order_date"
                      label="Order Date "
                      type="text"
                      fullWidth
                      value={order_date}
                      variant="outlined"
                      onChange={(e) => setorder_date(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="pair"
                      label="Pair "
                      fullWidth
                      value={pair}
                      variant="outlined"
                      //value={chat_id}
                      onChange={(e) => setpair(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <TextField
                      autoFocus
                      color="black"
                      style={{ color: "black" }}
                      margin="dense"
                      id="crypto"
                      label=" Crypto "
                      fullWidth
                      value={crypto}
                      variant="outlined"
                      //value={contact}
                      onChange={(e) => setcrypto(e.target.value)}
                    />
                  </Grid>{" "}
                  <Grid item xs={10}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="bank"
                      label={"Fiat " + "(" + pair?.split(/[/]+/)[1] + ")"}
                      fullWidth
                      value={fiat}
                      variant="outlined"
                      // value={bank}
                      onChange={(e) => setfiat(e.target.value)}
                    />
                  </Grid>{" "}

                  {OneOrder?.is_buy == 1 &&

                  <>
                  <Grid item xs={10}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="Wallet Name"
                      label="Wallet Name"
                      fullWidth
                      value={wallet}
                      variant="outlined"
                      // value={wallet}
                      onChange={(e) => setwallet(e.target.value)}
                    />
                  </Grid>{" "}
                  <Grid item xs={10}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="Wallet Address"
                      label="Wallet Address"
                      fullWidth
                      value={wallet_ad}
                      variant="outlined"
                      // value={wallet}
                      onChange={(e) => setwallet_ad(e.target.value)}
                    />
                  </Grid>{" "}
                  </>

                  }


                  {((OneOrder?.pair =="BTC/GBP" && OneOrder?.is_buy == 0) || (OneOrder?.pair =="USDT/GBP" && OneOrder?.is_buy == 0) )&&
                  <>
                  <Grid item xs={10}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="wallet"
                    label=" Bank Sort Number "
                    fullWidth
                    value={bank_sort_number}
                    variant="outlined"
                    onChange={(e) => setbank_sort_number(e.target.value)}
                  />
                  </Grid>{" "}
                  </>
                  } 
                  
                  
                  {(OneOrder?.is_buy == 0 || OneOrder?.pair=="USDT/GBP" || OneOrder?.pair=="BTC/GBP") &&
                  <>
                  <Grid item xs={10}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="wallet_add"
                      label=" Bank Name  "
                      fullWidth
                      value={bank}
                      variant="outlined"
                      onChange={(e) => setbank(e.target.value)}
                    />
                  </Grid>{" "}
                  </>
                  }

                  {OneOrder?.is_buy == 0 &&
                  <>
                  <Grid item xs={10}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="wallet_add"
                      label="Bank Account No"
                      fullWidth
                      value={bank_no}
                      variant="outlined"
                      onChange={(e) => setbank_no(e.target.value)}
                    />
                  </Grid>{" "}
                  </>
                  }


                  {OneOrder?.is_buy == 0 &&
                  <>
                  <Grid item xs={10}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="wallet_add"
                      label="Bank Holder Name"
                      fullWidth
                      value={bankRegisteredName}
                      variant="outlined"
                      onChange={(e) => setbankRegisteredName(e.target.value)}
                    />
                  </Grid>{" "}
                  </>
                  }
                





                  {(OneOrder?.pair == "BTC/SGD") || (OneOrder?.pair == "BTC/GBP") ?

                  <></>
                  :

                  <>
                  <Grid item xs={10}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="wallet_add"
                      label="Network"
                      fullWidth
                      value={network}
                      variant="outlined"
                      onChange={(e) => setNetwork(e.target.value)}
                    />
                  </Grid>{" "}
                  </>
                  }


                  <Grid item xs={10}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="kyc_status"
                      label=" Status "
                      fullWidth
                      value={kyc_status}
                      variant="outlined"
                      // value={kyc_status}
                      onChange={(e) => setkyc_status(e.target.value)}
                    />
                  </Grid>{" "}
                  <Grid item xs={10}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="active"
                      label=" Notes "
                      fullWidth
                      value={note}
                      variant="outlined"
                      onChange={(e) => setnote(e.target.value)}
                    />
                  </Grid>{" "}
                  <Button
                    style={{
                      width: "50%",
                      color: "white",
                      marginBlock: "15px",
                      margin: "auto",
                      marginRight: "30%",
                    }}
                    variant="contained"
                    onClick={handleUpdate}
                  >
                    save
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} md={5} lg={5}>
                <Typography>Send Message</Typography>
                <div
                  style={{
                    margin: "auto",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Telegram ID"
                    multiline
                    maxRows={4}
                    style={{ width: "100%", marginBlock: "15px" }}
                    onChange={(e) => setchat_id(e.target.value)}
                    value={chat_id}
                  />
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Header"
                    multiline
                    maxRows={4}
                    style={{ width: "100%", marginBlock: "15px" }}
                    onChange={(e) => setheader(e.target.value)}
                    value={header}
                  />
                  <br />
                  <TextField
                    id="outlined-multiline-static"
                    label="Message"
                    multiline
                    style={{ width: "100%" }}
                    rows={4}
                    onChange={(e) => settext(e.target.value)}
                    value={text}
                  />
                  <Button
                    style={{ width: "50%", color: "white", marginBlock: "15px" }}
                    variant="contained"
                    onClick={handlesubmit}
                  >
                    Send
                  </Button>
                </div>
              </Grid>
            </Grid>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "50px",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
                <h3 style={{ padding: "10px" }}>Loading ...</h3>
              </Box>
            </>
          )}
        </Card>
      </MDBox>
      <Toaster />
      <Footer />
    </DashboardLayout>
  );
}

export default Index;
