/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Telegram bot React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Telegram bot React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import Button from "@mui/material/Button";

import { useSelector, useDispatch } from "react-redux";
import { useDeleteGroupApi, useGetGroupsApi } from "apis/Groups";
import { useEffect, useState } from "react";
import Modal from "../modal";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
export default function data() {
  const [open2, setOpen2] = useState([]);
  const [refresh, setrefresh] = useState(false);

  const { mutate: DeleteGroupApi } = useDeleteGroupApi();
  const { data: GetGroupsApi } = useGetGroupsApi(refresh);

  const { Groups, error } = useSelector((state) => state.Groups);

  const HandleDelete = (id) => {
    DeleteGroupApi(id);
    // refetch();
    setTimeout(() => {
      setrefresh(!refresh);
    }, 1000);
  };
  useEffect(() => {
    var numofmodals = [];
    for (var i = 0; i < Groups?.length; i++) {
      numofmodals.push(false);
    }
    setOpen2([...numofmodals]);
  }, [Groups]);
  const handleClickOpen2 = (modalvalue, index) => {
    var allmodals = [...open2];

    allmodals[index] = modalvalue;
    setOpen2(allmodals);
  };

  useEffect(() => {}, [Groups, open2]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title.map((element) => {
          return (
            <Typography style={{ display: "block", marginInline: "2px" }}>
              {element.name}
            </Typography>
          );
        })}
      </MDTypography>
    </MDBox>
  );

  const Data = () => {
    let arrays = [];
    if (Groups) {
      for (var i = 0; i < Groups?.length; i++) {
        arrays.push({
          author: <Author name={Groups[i].name} email="" />,
          function: <Job title={Groups[i].permissions} description="" />,

          action: (
            <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
              <Button
                onClick={(e) => {
                  let id = Groups[i]?.id;
                  HandleDelete(e.target.id);
                }}
                style={{ backgroundColor: "#f44336", color: "white" }}
                variant="contained"
                id={Groups[i]?.id}
              >
                delete
              </Button>

              <Button
                onClick={(e) => {
                  handleClickOpen2(true, e.target.id);
                }}
                style={{
                  backgroundColor: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                  color: "white",
                  marginInline: "5px",
                }}
                variant="contained"
                id={i}
              >
                Edit
              </Button>
              <Modal open={open2} data={Groups[i]} index={i} setopen={setOpen2} />
            </MDTypography>
          ),
        });
      }
    }

    return arrays;
  };
  return {
    columns: [
      { Header: "name", accessor: "author", width: "45%", align: "left" },
      { Header: "permissions", accessor: "function", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: Groups ? [...Data()] : [],
  };
}
