/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Telegram bot React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Telegram bot React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import Button from "@mui/material/Button";

import { useSelector, useDispatch } from "react-redux";
import { useGetAdminUsersApi, useDeleteAdminUsersApi } from "apis/Admin_users";

import { useEffect, useState } from "react";
import Modal from "../modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../../components/Toaster/index";
export default function data() {
  const [open2, setOpen2] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [callrefresh, setcallrefresh] = useState(false);
  const { mutate: DeleteAdminUsersApi } = useDeleteAdminUsersApi();
  const { data: GetAdminUsersApi } = useGetAdminUsersApi(refresh);

  const [reresh, setreresh] = useState(false);
  const { AdminUsers, error } = useSelector((state) => state.AdminUsers);
  const notify = (success) => {
    if (success) {
      toast.success("user deleted Successfully !");
    } else {
      toast.error("error!");
    }
  };
  useEffect(() => {
    setrefresh(!refresh);
  }, [callrefresh]);
  const HandleDelete = (id) => {
    DeleteAdminUsersApi(id);
    // refetch();
    notify(true);

    setTimeout(() => {
      setcallrefresh(!callrefresh);
    }, 1000);
  };
  /*  useEffect(() => {
    var numofmodals = [];
    for (var i = 0; i < AdminUsers?.length; i++) {
      numofmodals.push(false);
    }
    setOpen2([...numofmodals]);
  }, [AdminUsers]);*/
  const handleClickOpen2 = (modalvalue, index) => {
    var allmodals = [...open2];

    allmodals[index] = modalvalue;
    setOpen2(allmodals);
  };

  useEffect(() => {}, [AdminUsers, open2]);
  const handleClose2 = () => {
    setOpen2(false);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {`${name}`}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );

  const Data = () => {
    let arrays = [];
    if (AdminUsers) {
      for (var i = 0; i < AdminUsers?.length; i++) {
        arrays.push({
          author: <Author name={AdminUsers[i].first_name} email="" />,
          Active: <Author name={AdminUsers[i].is_active} email="" />,
          function: <Job title={AdminUsers[i].email} description="" />,

          action: (
            <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
              <Toaster />

              <Button
                onClick={(e) => {
                  let id = AdminUsers[i]?.id;
                  HandleDelete(e.target.id);
                }}
                style={{ backgroundColor: "#f44336", color: "white" }}
                variant="contained"
                id={AdminUsers[i]?.id}
              >
                delete
              </Button>

              <Button
                onClick={(e) => {
                  handleClickOpen2(true, e.target.id);
                }}
                style={{
                  backgroundColor: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                  color: "white",
                  marginInline: "5px",
                }}
                variant="contained"
                id={i}
              >
                Edit
              </Button>
              <Modal open={open2} data={AdminUsers[i]} index={i} setopen={setOpen2} />
            </MDTypography>
          ),
        });
      }
    }

    return arrays;
  };
  return {
    columns: [
      { Header: "first name", accessor: "author", align: "left" },
      { Header: " Is Active", accessor: "Active", align: "left" },

      { Header: "Email", accessor: "function", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: AdminUsers ? [...Data()] : [],
  };
}
