import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "../layouts/authentication/AuthStore/Slice";
import UsersSlice from "./UsersStore/Slice";
import PostsSlice from "./PostsStore/Slice";
import OrdersSlice from "./OrdersStore/Slice";
import MarginsStore from "./MarginsStore/Slice";
import GroupsStore from "./GroupsSlice/Slice";
import AdminUsersStore from "./AdminUserSlice/Slice";
export const store = configureStore({
  reducer: {
    Auth: AuthSlice,
    Users: UsersSlice,
    Posts: PostsSlice,
    Orders: OrdersSlice,
    Margins: MarginsStore,
    Groups: GroupsStore,
    AdminUsers: AdminUsersStore,
  },
});
